"use strict";

import * as _ from "underscore";
import {Promise} from "es6-promise";
import {error} from "../utils/Log";
import {ApiServiceError, User, UserEmail} from "./VcsTypes";
import BbEventEmitter from "./BbEventEmitter";
import VcsEditorContext from "./VcsEditorContext";

const SESSION_CHECK_INTERVAL = 30000;

export default class VcsSession extends BbEventEmitter {

  static EVT_SESSION_ERROR: string = "session:error";

  private context: VcsEditorContext;
  private sessionData: User = {
    firstName: undefined,
    lastName: undefined,
    email: undefined
  };
  private userName: string;
  private validFlag = false;
  private isAlive = true;

  constructor(context: VcsEditorContext) {
    super();
    _.bindAll(this);
    this.context = context;
  }

  init(): Promise<VcsSession> {
    return this.getSession();
  }

  destroy() {
    this.isAlive = false;
  }

  private getSession(): Promise<VcsSession> {
    let vcsSession = this;
    return new Promise((resolve: any, reject: any) => {
      vcsSession.context.apiService.getUserSession()
        .then((user: User) => {
          vcsSession.validFlag = true;
          vcsSession.parseSessionData(user);
          setTimeout(vcsSession.checkSession, SESSION_CHECK_INTERVAL);
          resolve(vcsSession);
        }).catch((err: ApiServiceError) => {
          error("VcsSession.getSession ERROR:", err.errorThrown);
          vcsSession.validFlag = false;
          reject(err);
        });
    });
  }

  private parseSessionData(data: User): void {
    this.sessionData = data;
    this.userName = [data.firstName, data.lastName].join(" ");
  }

  // TODO test that checkSession() works as expected on session error
  private checkSession(): void {
    let vcsSession = this;

    if (this.isAlive) {
      this.getSession().catch((err: ApiServiceError) => {
        vcsSession.trigger(VcsSession.EVT_SESSION_ERROR, err);
      });
    }
  }

  isValid(): boolean {
    return this.validFlag;
  }

  getFirstName(): string {
    return this.sessionData.firstName;
  };

  getLastName(): string {
    return this.sessionData.lastName;
  };

  getEmail(): UserEmail {
    return this.sessionData.email;
  };

  getUserEmail(): UserEmail {
    return this.sessionData.email;
  };

  getUserName(): string {
    return this.userName;
  };

}
