"use strict";

export function isNonEmptyString(s: any): boolean {
  return typeof(s) === "string" && s.trim().length > 0;
}

export function isTruthy(s: any): boolean {
  if (s === undefined || s === null) return false;
  if (typeof(s) === "number") s += "";
  switch (s) {
    case "true":
    case "yes":
    case "on":
    case "1":
      return true;
  }
  return false;
}

export function truncateWithEllipsis(s: string, maxLength: number): string {
  return (s.length <= maxLength) ? s : s.slice(0, maxLength) + String.fromCharCode(8230);
}
