"use strict";

import * as _ from "underscore";
import {ApiService, ApiServiceContext, ScreenData, User, VcsEditorConfig} from "./VcsTypes";
import ProductConfig from "./ProductConfig";
import VcsSession from "./VcsSession";
import ProductLinks from "./ProductLinks";
//import * as URI from "URI";

export default class VcsEditorContext implements VcsEditorConfig, ApiServiceContext {

  elId: string;
  clientId: string;
  projectId: string;
  productId: string;
  screenId: string;
  screenIndex: number;
  screenArray: string[];
  baseUrl: string;
  //productBaseUrl: string;
  apiService: ApiService;
  productLinks: ProductLinks;
  productConfig: ProductConfig;
  initialScreenData: ScreenData;
  session: VcsSession;
  users: User[];

  constructor(options?: any) {
    _.bindAll(this);
    _.extend(this, options);
    //this.productBaseUrl = `/content/${this.clientId}/${this.projectId}/${this.productId}/`;
  }

  destroy() {
    if (this.session !== undefined) {
      this.session.destroy();
      this.session = undefined;
    }
  }

  // TODO remove if unused
  //productRelToAbsUrl(productRelUrl: string): string {
  //  return URI(productRelUrl).absoluteTo(this.productBaseUrl).href();
  //}

}
