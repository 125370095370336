"use strict";

import * as $ from "jquery";
import * as URI from "URI";
import {VcsEditor, VcsEditorConfig} from "./VcsTypes";
import BbEventEmitter from "./BbEventEmitter";
import VcsApiService from "./VcsApiService";
import VcsEditorContext from "./VcsEditorContext";
import VcsEditorImpl from "./VcsEditorImpl";

export interface VcsEditorWindow extends Window {
  VcsEditorApi: VcsEditorApi;
}

export default class VcsEditorApi extends BbEventEmitter {

  $: JQueryStatic;
  URI: Function;
  baseUrl: string;
  vcsEditor: VcsEditor;

  constructor() {
    super();
    $(() => { this.initializeOnce(); });
  }

  // guaranteed async callback after document load
  ready(callback: () => void) {
    $(() => setTimeout(() => {
      callback();
    }, 0));
  }

  initializeOnce() {
    if (typeof this.baseUrl !== "string") {
      let scriptUrl = document.querySelector("[data-vcs-editor-api]").getAttribute("src");
      this.baseUrl = URI(scriptUrl).absoluteTo(location.href).href();
      this.$ = $;
      this.URI = URI;
    }
  }

  createVcsEditor(config: VcsEditorConfig): VcsEditor {
    this.initializeOnce();

    if (this.vcsEditor !== undefined) {
      // Clean up the last editor that was created
      this.vcsEditor.destroy();
    }

    let context = new VcsEditorContext(config);
    context.baseUrl = this.baseUrl;
    context.apiService = new VcsApiService(context);
    let editor: VcsEditor = this.vcsEditor = new VcsEditorImpl(context);
    this.ready((<VcsEditorImpl>editor).initialize);
    return editor;
  }

  /**
   * Returns the first VcsEditor instance found in this window or any
   * parent windows.
   */
  getVcsEditor(): VcsEditor {
    if (!this.vcsEditor) {
      let w = window, p = parent;
      while (!this.vcsEditor && p && p !== w) {
        let VcsEditorApi: VcsEditorApi = (<VcsEditorWindow>p).VcsEditorApi;
        if (VcsEditorApi && VcsEditorApi.vcsEditor) {
          this.vcsEditor = VcsEditorApi.vcsEditor;
        } else {
          w = p;
          p = w.parent;
        }
      }
    }
    return this.vcsEditor;
  }

  destroy() {
    this.vcsEditor = undefined;
  }

}
