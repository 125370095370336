"use strict";

import * as Backbone from "backbone";
import * as _ from "underscore";

export default class BbEventEmitter implements Backbone.Events {

  constructor() {
    _.extend(this, Backbone.Events);
  }

  on(eventName: string, callback: Function, context?: any): any {
    return undefined;
  }

  off(eventName: string, callback?: Function, context?: any): any {
    return undefined;
  }

  trigger(eventName: string, ...args: any[]): any {
    return undefined;
  }

  bind(eventName: string, callback: Function, context?: any): any {
    return undefined;
  }

  unbind(eventName: string, callback?: Function, context?: any): any {
    return undefined;
  }

  once(events: string, callback: Function, context?: any): any {
    return undefined;
  }

  listenTo(object: any, events: string, callback: Function): any {
    return undefined;
  }

  listenToOnce(object: any, events: string, callback: Function): any {
    return undefined;
  }

  stopListening(object: any, events?: string, callback?: Function): any {
    return undefined;
  }

}
