"use strict";

import * as _ from "underscore";
import * as URI from "URI";
import * as uuid from "node-uuid";
import {error, log} from "../utils/Log";
import BbEventEmitter from "./BbEventEmitter";
import VcsSession from "./VcsSession";
import ProductConfig from "./ProductConfig";
import VcsEditorContext from "./VcsEditorContext";
import * as Types from "./VcsTypes";
import ProductLinks from "./ProductLinks";

export default class VcsEditorImpl extends BbEventEmitter implements Types.VcsEditor {

  context: VcsEditorContext;
  getScreenEditorApi: () => Types.ScreenEditorApi;
  getBlockEditorApi: () => Types.BlockEditorApi;
  getFlagEditorApi: () => Types.FlagEditorApi;

  private editorWindow: Window;
  public id: String;

  constructor(context: VcsEditorContext) {
    super();
    this.id = uuid.v1();
    log("new VcsEditorImpl:", this.id, context);
    this.context = context;
    _.bindAll(this);
  }

  destroy() {
    this.removeEventListeners();

    if (this.context !== undefined) {
      this.context.destroy();
      this.context = undefined;
    }

    this.editorWindow = undefined;
  }

  initialize(): void {
    this.addEventListeners();
    this.initProductLinks();
    this.initSession();
  }

  private addEventListeners(): void {
    window.addEventListener("message", this.onWindowMessage);
  }

  private removeEventListeners(): void {
    window.removeEventListener("message", this.onWindowMessage);
  }

  private initSession(): void {
    let vcsEditor = this;
    new VcsSession(this.context).init()
      .then((session: VcsSession) => {
        this.listenTo(session, VcsSession.EVT_SESSION_ERROR, this.onSessionError);
        vcsEditor.context.session = session;
        vcsEditor.initUsers();
      }).catch((err: Types.ApiServiceError) => {
        // TODO redirect to login page?
        error("VcsEditorImpl.initSession ERROR:", err);
      });
  }

  private initUsers(): void {
    let vcsEditor = this;
    this.context.apiService.getProjectUsers()
      .then((users: Types.User[]) => {
        vcsEditor.context.users = users;
        vcsEditor.initProductConfig();
        //vcsEditor.initProductLinks();
      }).catch((err: Types.ApiServiceError) => {
        error("VcsEditorImpl.initUsers ERROR:", err);
      });
  }

  private initProductLinks(): void {
    let vcsEditor = this;
    new ProductLinks(this.context.apiService).init()
      .then((links: ProductLinks) => {
        vcsEditor.context.productLinks = links;
        //vcsEditor.initProductConfig();
      }).catch((err: Types.ApiServiceError) => {
        vcsEditor.context.productLinks = new ProductLinks(null);
        error("VcsEditorImpl.initProductLinks ERROR:", err);
    });
  }

  // TODO initialize productConfig from productData?
  private initProductConfig(): void {
    let vcsEditor = this;
    new ProductConfig(this.context.apiService).init()
      .then((productConfig: ProductConfig) => {
        vcsEditor.context.productConfig = productConfig;
        vcsEditor.initScreenData();
      }).catch((err: Types.ApiServiceError) => {
        error("VcsEditorImpl.initProductConfig ERROR:", err);
      });
  }

  private initScreenData(): void {
    let vcsEditor = this;
    this.context.apiService.getScreen()
      .then((fullScreenData: Types.FullScreenData) => {
        vcsEditor.context.initialScreenData = fullScreenData;
        vcsEditor.loadEditorIframe();
      }).catch((err: Types.ApiServiceError) => {
        error("VcsEditorImpl.initScreenData ERROR:", err);
      });
  }

  private loadEditorIframe(): void {
    let context = this.context;
    let el: HTMLElement = document.getElementById(context.elId);
    let iframe: HTMLIFrameElement = document.createElement("iframe");
    let pageUrl: string = URI("../pages/screeneditor.html")
      .absoluteTo(context.baseUrl).href();
    iframe.setAttribute("src", pageUrl);
    iframe.setAttribute("allowfullscreen", "true");
    iframe.classList.add("vcs-screeneditor-frame");
    // see http://davidwalsh.name/iframe-contentwindow-null
    iframe.onload = () => {
      this.editorWindow = iframe.contentWindow;
    };
    el.appendChild(iframe);
  }

  private onSessionError(err: Types.ApiServiceError): void {
    // TODO alert user and redirect to login page?
    error("VcsEditorImpl.onSessionError:", err);
  }

  private onWindowMessage(event: MessageEvent): void {
    let message = event.data;
    this.logMessage("VcsEditorImpl RECEIVE", message.type, message.data);
    //this.trigger(message.type, message.data);
  }

  private logMessage(direction: string, type: any, data?: any): void {
    let dataStr = (data) ? " => " + JSON.stringify(data, null, 2) : "";
    log(direction, type, dataStr);
  }
}
