"use strict";

import { DOMParser as NodeDOMParser, XMLSerializer as NodeXMLSerializer } from "xmldom";
import { isNonEmptyString } from "./StringU";

interface XmlUDocument {
  documentElement: Element;
  loadXML(xmlStr: string): void;
  removeChild(el: Element): Element;
}

/**
 * Parses str into an XML Element and returns it,
 * optionally detached from its parent Document.
 */
export function parseXml(str: string, detached = true): Element {
  let doc: XmlUDocument | Document = null;
  if (typeof DOMParser !== "undefined") {
    doc = (new DOMParser()).parseFromString(str, "application/xml");
  } else if (typeof ActiveXObject !== "undefined") {
    doc = new ActiveXObject("MSXML2.DOMDocument");
    (<XmlUDocument>doc).loadXML(str);
  } else {
    doc = (new NodeDOMParser()).parseFromString(str, "application/xml");
  }
  if (doc !== null) {
    return (detached)
      ? (<XmlUDocument>doc).removeChild(doc.documentElement)
      : doc.documentElement;
  } else {
    return null;
  }
}

export function getNumberOfChildElements(node: Element): number {
  let len = 0,
      n = node.firstChild;
  for ( ; n !== null; n = n.nextSibling) {
    if (n.nodeType === 1) len++;
  }
  return len;
}

export function getFirstChildElement(node: Element): Element {
  return <Element>getFirstChildOfType(node, 1);
}

export function getLastChildElement(node: Element): Element {
  return <Element>getLastChildOfType(node, 1);
}

export function getFirstChildOfType(node: Node, childNodeType: number): Node {
  for (let n = node.firstChild; n !== null; n = n.nextSibling) {
    if (n.nodeType === childNodeType) return n;
  }
}

export function getLastChildOfType(node: Node, childNodeType: number): Node {
  for (let n = node.lastChild; n !== null; n = n.previousSibling) {
    if (n.nodeType === childNodeType) return n;
  }
}

export function getAttr(node: Element, attrName?: string, defaultValue?: string): string {
  let attrValue = node.getAttribute(attrName);
  return (isNonEmptyString(attrValue))
    ? attrValue : defaultValue;
}

export function getNodeDepth(node: Node): number {
  let d = 0;
  for ( ; node.parentNode !== null; d++, node = node.parentNode);
  return d;
}

/**
 * See http://api.jquery.com/parentsUntil/
 */
export function parentsUntil(node: Element, untilNode: Element): Element[] {
  let parentEls: Element[] = [];
  while (node.parentNode
      && node.parentNode !== untilNode
      && node.parentNode !== node.ownerDocument) {
    node = <Element>node.parentNode;
    parentEls.push(node);
  };
  return parentEls;
}

export function extendAttributesInPlace(el1: Element, el2: Element, override = false): void {
  let attrs = el2.attributes,
      attr: Attr;
  for (let i = attrs.length - 1; i >= 0; i--) {
    attr = attrs[i];
    if (override || !el1.hasAttribute(attr.name)) {
      el1.setAttribute(attr.name, attr.value);
    }
  }
}

export function setNodeText(node: Node, text: string): void {
  let textNode = node.ownerDocument.createTextNode(text);
  while (node.hasChildNodes()) node.removeChild(node.firstChild);
  node.appendChild(textNode);
}

export function serialize(node: Node): string {
  if (typeof XMLSerializer !== "undefined") {
    return (new XMLSerializer()).serializeToString(node);
  } else if ("xml" in node) {
    return (<any>node).xml;
  } else if (typeof NodeXMLSerializer !== "undefined") {
    return (new NodeXMLSerializer()).serializeToString(node);
  } else {
    throw "XmlU.serialize() not supported.";
  }
}
