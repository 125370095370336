"use strict";

import * as $ from "jquery";
import * as Backbone from "backbone";
import {Promise} from "es6-promise";
import * as StringU from "../utils/StringU";
import * as Types from "./VcsTypes";
import {Queue} from "./Queue";

// see http://www.2ality.com/2015/01/template-strings-html.html
const sessionUrl = "/api/session";
const projectUrlTpl = (context: Types.ApiServiceContext) => `/api/projects/${context.projectId}`;
const projectUsersUrlTpl = (context: Types.ApiServiceContext) => `${projectUrlTpl(context)}/users`;
const productUrlTpl = (context: Types.ApiServiceContext) => `${projectUrlTpl(context)}/products/${context.productId}`;
const productLinksUrlTpl = (context: Types.ApiServiceContext) => `${productUrlTpl(context)}/links`;
const productConfigUrlTpl = (context: Types.ApiServiceContext) => `${productUrlTpl(context)}/config`;
const editorsUrlTpl = (context: Types.ApiServiceContext) => `${productUrlTpl(context)}/editors`;
const enginesUrlTpl = (context: Types.ApiServiceContext) => `${productUrlTpl(context)}/engines`;
const iconsUrlTpl = (context: Types.ApiServiceContext) => `${productUrlTpl(context)}/icons`;
const mediaUrlTpl = (context: Types.ApiServiceContext, baseDirectory: string) => `${productUrlTpl(context)}/${baseDirectory}`;
const skinsUrlTpl = (context: Types.ApiServiceContext) => `${productUrlTpl(context)}/skins`;
const templatesUrlTpl = (context: Types.ApiServiceContext) => `${productUrlTpl(context)}/templates`;
const resourceUrlTpl = (context: Types.ApiServiceContext, type: String) => `${productUrlTpl(context)}/${type}`;
const screenUrlTpl = (context: Types.ApiServiceContext) => `${productUrlTpl(context)}/contents/${context.screenId}`;
//const screenCommentsUrlTpl = (context: Types.ApiServiceContext) => `${screenUrlTpl(context)}/comments`;
const blocksUrlTpl = (context: Types.ApiServiceContext) => `${screenUrlTpl(context)}/blocks`;
const blockUrlTpl = (context: Types.ApiServiceContext, blockId: string) => `${blocksUrlTpl(context)}/${blockId}`;
const blockCommentsUrlTpl = (context: Types.ApiServiceContext, blockId: string) => `${blockUrlTpl(context, blockId)}/comments`;

interface RestApiResponse {
  code: number;
  data: {};
  error: {};
  message: string;
  messageid: string;
  metadata: {};
  version: number;
}

export default class VcsApiService extends Backbone.Model implements Types.ApiService {

  private context: Types.ApiServiceContext;
  private updateQueue: Queue;

  constructor(context: Types.ApiServiceContext) {
    super();
    this.context = context;
    this.updateQueue = new Queue(1);
  }

  getUserSession(): Promise<Types.User> {
    return this.getJson(sessionUrl);
  }

  getProjectUsers(): Promise<Types.User[]> {
    return this.getJson(projectUsersUrlTpl(this.context));
  }

  getProductData(): Promise<Types.FullProductData> {
    return this.getJson(productUrlTpl(this.context))
      .then((data: Types.FullProductData) => {
        if (typeof data === "string") {
          data = JSON.parse(<any>(data));
        }
        return data;
      });
  }

  getProductLinks(): Promise<Types.TocItemData[]> {
    return this.getJson(productLinksUrlTpl(this.context))
      .then((links: Types.TocItemData[]) => {
        if (typeof links === "string") {
          links = JSON.parse(<any>(links));
        }
        return links;
      });
  }

  getProductConfig(): Promise<Types.ProductConfig> {
    return this.getJson(productConfigUrlTpl(this.context))
      .then((config: Types.ProductConfig) => {
        if (typeof config.config === "string") {
          config.config = JSON.parse(<any>(config.config));
        }
        return config;
      });
  }

  getEditorsBaseUrl(): string {
    return editorsUrlTpl(this.context);
  }

  getEnginesBaseUrl(): string {
    return enginesUrlTpl(this.context);
  }

  getIconsBaseUrl(): string {
    return iconsUrlTpl(this.context);
  }

  getMediaBaseUrl(baseDirectory = "media"): string {
    return mediaUrlTpl(this.context, baseDirectory);
  }

  getSkinsBaseUrl(): string {
    return skinsUrlTpl(this.context);
  }

  getTemplatesBaseUrl(): string {
    return templatesUrlTpl(this.context);
  }

  getResourceBaseUrl(type: String): string {
    return resourceUrlTpl(this.context, type);
  }

  getScreen(): Promise<Types.FullScreenData> {
    return this.getJson(screenUrlTpl(this.context));
  }

  putScreen(data: Types.ScreenData): Promise<Types.ScreenData> {
    return this.updateQueue.push<Types.ScreenData>({
      callback: this.putJson,
      args: [screenUrlTpl(this.context), data],
      scope: this
    });
  }

  postBlock(data: Types.BlockData): Promise<Types.IdData> {
    return this.updateQueue.push<Types.ScreenData>({
      callback: this.postJson,
      args: [blocksUrlTpl(this.context), data],
      scope: this
    });
  }

  duplicateBlock(data: Types.BlockData): Promise<Types.IdData> {
    return this.updateQueue.push<Types.IdData>({
      callback: this.postJson,
      args: [blocksUrlTpl(this.context), data],
      scope: this
    });
  }

  putBlock(data: Types.BlockData): Promise<Types.IdData> {
    return this.updateQueue.push<Types.IdData>({
      callback: this.putJson,
      args: [blockUrlTpl(this.context, data.id), data],
      scope: this
    });
  }

  deleteBlock(data: Types.BlockRefData): Promise<void> {
    return this.updateQueue.push<void>({
      callback: this.postJson,
      args: [blockUrlTpl(this.context, data.id), data],
      scope: this
    });
  }

  getBlockComments(blockId: string): Promise<Types.Comment[]> {
    return this.getJson(blockCommentsUrlTpl(this.context, blockId))
      .then((comments: Types.Comment[]) => {
        // return comments in order of descending creation date
        return comments.sort((a, b): number => {
          return b.dateTime - a.dateTime;
        });
      });
  }

  postBlockComment(blockId: string, text: string): Promise<Types.Comment> {
    return this.postJson(blockCommentsUrlTpl(this.context, blockId), {
      text: text
    });
  }

  private getJson(url: string): Promise<any> {
    return this.promisifyApiRequest($.getJSON(url));
  }

  private postJson(url: string, jsonData: any): Promise<any> {
    return this.promisifyApiRequest($.ajax(url, {
      data: JSON.stringify(jsonData),
      contentType: "application/json; charset=utf-8",
      dataType: "json",
      method: "POST"
    }));
  }

  private putJson(url: string, jsonData: any): Promise<any> {
    return this.promisifyApiRequest($.ajax(url, {
      data: JSON.stringify(jsonData),
      contentType: "application/json; charset=utf-8",
      dataType: "json",
      method: "PUT"
    }));
  }

  // private deleteJson(url: string): Promise<any> {
  //  return this.promisifyApiRequest($.ajax(url, {
  //    dataType: "json",
  //    method: "DELETE"
  //  }));
  // }

  private promisifyApiRequest(request: JQueryXHR): Promise<any> {
    return new Promise((resolve: any, reject: Function) => {
      request.done((data: RestApiResponse, textStatus: string, jqXHR: JQueryXHR) => {
        if (data.code >= 400) {
          let err: Types.ApiServiceError = {
            jqXHR: jqXHR,
            textStatus: "error",
            errorThrown: this.getErrorReason(data),
            errorCode: data.code
          };
          reject(err);
        } else {
          // see https://wiki.ingramcontent.com/display/FLOE/REST+APIs -- data should always be
          // returned as the value of a REST API response's "data" field
          resolve(data.data);
        }
      }).fail((jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => {
        // TODO or simply reject with jqXHR.responseJSON?
        let errorCode = (jqXHR.readyState === 4) ? jqXHR.status : 0,
            err: Types.ApiServiceError = {
              jqXHR: jqXHR,
              textStatus: textStatus,
              errorThrown: errorThrown,
              errorCode: errorCode
            };
        reject(err);
      });
    });
  }

  private getErrorReason(data: RestApiResponse): string {
    if (StringU.isNonEmptyString(data.message)) {
      return data.message;
    } else if (StringU.isNonEmptyString(data.messageid)) {
      return data.messageid;
    } else {
      return "Unknown Error";
    }
  }
}
