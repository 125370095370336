"use strict";

declare var exports: any;
let logModule = exports;

export enum LogLevel { log, info, warn, error }

function noop(): void {
  // do nothing
}

export function log(...args: any[]): void {
  // vectored to original console.log or noop depending on setLevel
}

export function info(...args: any[]): void {
  // vectored to original console.log or noop depending on setLevel
}

export function warn(...args: any[]): void {
  // vectored to original console.log or noop depending on setLevel
}

export function error(...args: any[]): void {
  // vectored to original console.log or noop depending on setLevel
}

export function setLevel(levels: LogLevel[]): void {
  "use strict";
  let logFuncs: string[] = ["log", "info", "warn", "error"];
  for (let func of logFuncs) {
    if (levels.indexOf(logFuncs.indexOf(func)) !== -1) {
      if (Function.prototype.bind) {
        logModule[func] = Function.prototype.bind.call(console[func], console);
      } else {
        logModule[func] = Function.prototype.apply.call(console[func], console,  arguments);
      }
    } else {
      logModule[func] = noop;
    }
  }
}

(function(): void {
  // keep init after function stub exports above

  // à la html5 boilerplate, noop console functions when not available
  let funcs: string[] = [
    "assert", "clear", "count", "debug", "dir", "dirxml", "error",
    "exception", "group", "groupCollapsed", "groupEnd", "info", "log",
    "markTimeline", "profile", "profileEnd", "table", "time", "timeEnd",
    "timeStamp", "trace", "warn"
  ];
  let console: any = window.console ? window.console : {};
  for (let func of funcs) {
    if (!console[func]) {
      console[func] = noop;
    }
  }
  setLevel([LogLevel.log, LogLevel.info, LogLevel.warn, LogLevel.error]);
})();




