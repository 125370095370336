"use strict";

import * as _ from "underscore";
import {Promise} from "es6-promise";
import {error} from "../utils/Log";
import {ApiService, ApiServiceError, TocItemData, ProductLinks as IProductLinks} from "./VcsTypes";

export default class ProductLinks {

  private apiService: ApiService;

  toc: TocItemData[] = [];

  constructor(apiService: ApiService) {
    _.bindAll(this);
    this.apiService = apiService;
  }

  init(): Promise<IProductLinks> {
    let productLinks = this;
    return new Promise((resolve: any, reject: any) => {
      productLinks.apiService.getProductLinks()
        .then((links: TocItemData[]) => {
          productLinks.parseProductLinks(links);
          resolve(productLinks);
        }).catch((err: ApiServiceError) => {
          error("ProductLinks.init ERROR:", err);
          reject(err);
        });
    });
  }

  private parseProductLinks(links: TocItemData[]): void {
    this.toc = links;
  }
}
