"use strict";

import * as $ from "jquery";
import * as _ from "underscore";
import * as jsonQ from "jsonq";
import {Promise} from "es6-promise";
import {log, error} from "../utils/Log";
import * as XmlU from "../utils/XmlU";
import {ApiService, ApiServiceError, BlockTypeInfoMap, ProductConfig as IProductConfig,
  MediainputConfig, RichtextConfig, XmlString} from "./VcsTypes";

export default class ProductConfig {

  private apiService: ApiService;

  configData: IProductConfig;
  productScreenEditorUrl: string;
  blocktypes: BlockTypeInfoMap;
  bloxMenu: any;
  screenSchemas: any;
  blockSchemas: any;
  richtextConfigs: any;
  mediainputConfigs: any;

  constructor(apiService: ApiService) {
    _.bindAll(this);
    this.apiService = apiService;
  }

  init(): Promise<ProductConfig> {
    let productConfig = this;
    return new Promise((resolve: any, reject: any) => {
      productConfig.apiService.getProductConfig()
        .then((config: IProductConfig) => {
          productConfig.parseConfigData(config);
          resolve(productConfig);
        }).catch((err: ApiServiceError) => {
          error("ProductConfig.init ERROR:", err);
          reject(err);
        });
    });
  }

  private parseConfigData(data: IProductConfig): void {
    data = this.resolveResourceUrls(data);
    this.configData = data;
    this.productScreenEditorUrl = data.config.screenEditorUrl;
    this.blocktypes = data.config.blocktypes;
    this.bloxMenu = this.parseBloxMenu(data.config.bloxMenu, data.config.blocktypes);
    this.screenSchemas = this.parseSchemas(data.screentypes);
    this.blockSchemas = this.parseSchemas(data.blocktypes);
    this.richtextConfigs = this.parseRichtextConfigs(data.config.richtext.configs);
    this.mediainputConfigs = this.parseMediainputConfigs(data.config.mediainput.configs);
  }

  private resolveResourceUrls(data: IProductConfig): IProductConfig {
    let resourceRe = /\$\{product\.(.+?)\.url}/mg,
        apiService = this.apiService;
    let dataStr = JSON.stringify(data).replace(resourceRe, (match, p1) => {
      return apiService.getResourceBaseUrl(p1);
    });
    return JSON.parse(dataStr);
  }

  // replace all blocktype ID strings in bloxMenu's "children" arrays
  // with the corresponding metadata from this config's "blocktypes" key
  // (which in turn will have been populated from the global blocktypes-registry.json)
  private parseBloxMenu(bloxMenu: any, blocktypes: BlockTypeInfoMap): any {
    let config = this,
        bloxMenuQ = jsonQ(bloxMenu);
    bloxMenuQ.find("menuItems").value((menu: any[]) => {
      let newItems = _.map(menu, (value: any, index: number, all: any[]) => {
        return (typeof value === "string")
          ? _.assign(blocktypes[value], { eid: value })
          : value;
      });
      return _.filter(newItems, config.isValidBloxMenuEntry);
    });
    log("ProductConfig.parseBloxMenu:", bloxMenu);
    return bloxMenu;
  }

  private isValidBloxMenuEntry(value: any): boolean {
    return value && (this.isBloxMenuMenu(value) || this.isBlockTypeInfo(value));
  }

  private isBloxMenuMenu(value: any): boolean {
    return typeof value.menuLabel === "string" && _.isArray(value.menuItems);
  }

  private isBlockTypeInfo(value: any): boolean {
    return typeof value.editable === "boolean"
      && _.every(["icon", "label", "implCat", "salesCat"], (key: string) => key in value);
  }

  private parseSchemas(schemas: XmlString): any {
    let schemasEl = XmlU.parseXml(schemas);
    let schemaMap = {};
    $(schemasEl).children().each((idx: number, el: Element) => {
      schemaMap[el.nodeName] = (<HTMLElement>el).outerHTML;
    });
    return schemaMap;
  }

  // returns a configs map guaranteed to have a "default" key
  // and for all other keys to inherit any undefined RichtextConfig values from it
  private parseRichtextConfigs(configs: { [index: string]: RichtextConfig }): { [index: string]: RichtextConfig } {
    let defaultConfig: RichtextConfig = {
        editorImpl: "ckeditor",
        editorConfig: "built-in"
      };
    if (configs.hasOwnProperty("default")) _.assign(defaultConfig, configs["default"]);
    configs["default"] = defaultConfig;
    _.each(configs, (config: RichtextConfig, key: string) => {
      configs[key] = <RichtextConfig>_.assign({}, defaultConfig, config);
    });
    return configs;
  }

  private parseMediainputConfigs(configs: { [index: string]: MediainputConfig }): { [index: string]: MediainputConfig } {
    let defaultConfig: MediainputConfig = {
      finderImpl: "mediafinder",
      finderConfig: "built-in"
    };
    if (configs.hasOwnProperty("default")) _.assign(defaultConfig, configs["default"]);
    configs["default"] = defaultConfig;
    _.each(configs, (config: MediainputConfig, key: string) => {
      configs[key] = <MediainputConfig>_.assign({}, defaultConfig, config);
    });
    return configs;
  }
}
