import {Promise} from "es6-promise";
import * as async from "async";

type queueCallback = (err?: Error, value?: any) => void;

export interface QueueTask<T> {
    callback: (...args: any[]) => Promise<T>;
    args?: any[];
    scope?: Object;
}

export class Queue {

    private queue: AsyncQueue<QueueTask<any>>;

    constructor(concurrency: number) {

        this.queue = async.queue((task: QueueTask<any>, callback: queueCallback): void => {
            const that: Object = task.scope ? task.scope : this;
            Promise.resolve().then(() => {
                if (task.args && task.args.length > 0) {
                    return task.callback.apply(that, task.args);
                }

                return task.callback.call(that);
            }).then((value: any) => {
                callback.call(that, null, value);
            }).catch((err: any) => {
                callback.call(that, err);
            });
        }, concurrency);
    }

    get concurrency(): number {
        return this.queue.concurrency;
    }

    set concurrency(n: number) {
        this.queue.concurrency = n;
    }

    push<T>(task: QueueTask<T>): Promise<T> {
        return new Promise<T>((resolve: Function, reject: Function) => {
            this.queue.push(task, (err: Error, value?: T) => {
                if (err) {
                    reject(err);
                }

                resolve(value);
            });
        });
    }
}
